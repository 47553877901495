import React, {useContext, useEffect} from 'react';
import { useNavigate} from "react-router-dom";
import GoBack from '../img/left-arrow.png';
import { AppContext } from './context';
import { makeAuthRequest } from './context/utils';
import Cookies from 'js-cookie';
import styles from './style/Navbar.module.scss';
import lang from './language/Controlbar.json'
import config from '../config/config.json'

const IncrementalControlBar = () => {
  const { date, setDate, search, setSearch, setIncrementalList, currentPreInspectionPage, agentSearch, setAgentSearch, language, approvalType, setApprovalType, searchInspection, setSearchInspection, setLoading, uniqueSearch, setUniqueSearch } = useContext(AppContext)

  let navigate = useNavigate()
    const {urlLink, octoClients} = config

  //function gets filtered result from backend by sending date/case/inspection/licence
  const getFilteredResult = async (type, value) => {
    if(type === 'date') setDate(value)
    else if (type === 'agent') setAgentSearch(value)
    else if (type === 'car_condition') setUniqueSearch(value)
    else if (type === 'agent_approved') setApprovalType(value)
    else setSearch(value)

    if(value === '') return getInspectionData(currentPreInspectionPage)

    if(value.length < 5) return

    const res = await makeAuthRequest(`${urlLink}incremental-vehicle-listing-filter`, {search_value: value, search_type: type, client_id: Cookies.get('client'), client_type: Cookies.get('clientType'), agent_id: "", tl_id: Cookies.get("tlId")})
    const data = await res.json()
    setIncrementalList(data)
    setLoading(false)
  }


  // function recieves inspection data list from backend
  const getInspectionData = (page) => {

    return new Promise((resolve, reject) => {

      // limit: number of inspection data on a single page, page_no: page number displayed on screen
      makeAuthRequest(`${urlLink}incremental-vehicle-list`, {client_id: Cookies.get('master'), limit: 20, page_no: page, client_type: Cookies.get('clientType'), agent_tl_view: Cookies.get("tlView"), tl_id: Cookies.get("tlId"), agent_id: Cookies.get('clientType') === "agent" ? Cookies.get("client") : ""})
      .then((res)=> res.json()
      .then((data)=>{

      //setting preinspection list as a state to be shown on screen
      setIncrementalList(data)
      resolve({status: true})
      })).catch((error)=>{
        console.log(error)
        reject("Error during requesting inspection data")
      })
    })
  }

  
  return (
    <nav className={styles.controlBar}>
      <div className={styles.controlBarContainer}>
        <div className="controlbar-left">
          <button onClick={ () => navigate('/') } className={styles.controlBarBackButton}>
            <img  src={GoBack} alt="Back"  />
            {lang["Back to Menu"][language]}
          </button>
        </div>
        <div className={styles.controlBarForm}>
        <label>{lang["Filter By"][language]}</label>
          <input
            type="date"
            value={date}
            onChange={(e) => getFilteredResult('date', e.target.value)}
            placeholder={lang["date"][language]}
            className={styles.controlBarInputDate}
          />
          <input
            type="text"
            value={agentSearch}
            onChange={(e) => getFilteredResult('agent', e.target.value)}
            placeholder={lang["Agent Name"][language]}
            className={styles.controlBarInputId}
          />
          <input
            type="text"
            value={search}
            onChange={(e) => getFilteredResult('license', e.target.value)}
            placeholder="license Plate"
            className={styles.controlBarInputId}
          />
          <input
            type="text"
            value={uniqueSearch}
            onChange={(e) => getFilteredResult('car_condition', e.target.value)}
            placeholder="Car Condition"
            className={styles.controlBarInputId}
          />
          <select value={approvalType} onChange={(e)=>getFilteredResult("agent_approved", e.target.value)}>
            <option className={styles.options} value="">{lang["Approval Filter"][language]}</option>
            <option className={styles.options} value="Approve">{lang["Approve"][language]}</option>
            <option className={styles.options} value="Disapprove">{lang["Disapprove"][language]}</option>
            <option className={styles.options} value="Action Needed">{lang["Action Needed"][language]}</option>
          </select>
        </div>
      </div>
    </nav>
  );
};

export default IncrementalControlBar;
