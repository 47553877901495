import React , {useContext, useEffect} from 'react'
import Navbar from './Navbar'
import { useNavigate } from 'react-router-dom'
import styles from './style/PreInspection.module.scss'
import Edit from '../img/preInspection/edit.png'
import IncrementalControlBar from './IncrementalControlBar'
import logoFooter from '../img/preInspection/footer.png'
import nextBtn from '../img/preInspection/blueNext.png'
import loadingImg from '../img/loading.gif'
import { AppContext } from './context'
import { checkCookies, removeCookies, makeAuthRequest } from './context/utils'
import Cookies from 'js-cookie'
import lang from './language/PreInspection.json'
import conf from '../config/config.json'
import rightArrowAngle from '../img/right-arrow-angle.png' 
import car from "../img/carFleet.png"

let license = ""
let shortMonths = ['Dec', 'Nov', 'Oct', 'Sep', 'Aug', 'Jul', 'Jun', 'May', 'Apr', 'Mar', 'Feb', 'Jan']

const  IncrementalDamage = () =>{
  const {urlLink, octoClients} = conf
  const { language, preInspectionList, setPreInspectionList, currentPreInspectionPage, setCurrentPreInspectionPage, totalPages,
          setTotalPages, loading, setLoading, showRemarkModal, setShowRemarkModal, remark, setRemark, setDate, setSearch, setAgentSearch,
          setLanguage, setLanguageList, setModule, searchInspection, reportMonth, setReportMonth, reportDownloading, setReportDownloading,
          incrementalList, setIncrementalList} = useContext(AppContext)

  const navigate = useNavigate()

  // function recieves inspection data list from backend
  const getInspectionData = (page) => {

    return new Promise((resolve, reject) => {

      // limit: number of inspection data on a single page, page_no: page number displayed on screen
      makeAuthRequest(`${urlLink}incremental-vehicle-list`, {client_id: Cookies.get('master'), limit: 20, page_no: page, client_type: Cookies.get('clientType'), agent_tl_view: Cookies.get("tlView"), tl_id: Cookies.get("tlId"), agent_id: Cookies.get('clientType') === "agent" ? Cookies.get("client") : ""})
      .then((res)=> res.json()
      .then((data)=>{

      //setting preinspection list as a state to be shown on screen
      setIncrementalList(data)
      resolve({status: true})
      })).catch((error)=>{
        console.log(error)
        reject("Error during requesting inspection data")
      })
    })
  }

  //funtion returns and sets total pages to be displayed on screen
  const getTotalClientInspectionCount = async (page) => {

    return new Promise((resolve, reject)=>{

      makeAuthRequest(`${urlLink}count-incremental-vehicle-listing`, {client_id: Cookies.get('master'), client_type: Cookies.get('clientType'), agent_tl_view: Cookies.get("tlView"), tl_id: Cookies.get("tlId"), agent_id: Cookies.get('clientType') === "agent" ? Cookies.get("client") : ""})
      .then((res)=> res.json()
      .then((data)=>{

        //limiting page count to 10
        let limit = data.count
        if (limit === 0){
          return resolve({status: true})
        } 

        if(data.count > 200){
          limit = 200
        }
        let pageLimit = Math.ceil(limit / 20)
        let temp = []
        for(let i = 1; i <= pageLimit; i++){
          temp.push(i)
        }
        setTotalPages(temp)
        setCurrentPreInspectionPage(page)
        resolve({status: true})

      })).catch((error)=>{
        console.log(error)
        reject("Error during requesting inspection count")
      })
    })
  }
  
  //function controls pagination arrows
  const onChangePage = (direction, value) => {
    let temp = 0
    if(direction === 'left'){
      if(currentPreInspectionPage > value){
        setLoading(true)
        temp = currentPreInspectionPage - value
        setCurrentPreInspectionPage(temp)
        Promise.all([getInspectionData(temp), getTotalClientInspectionCount(temp)]).then((data)=>{
          setLoading(false)
          setSearch('')
          setAgentSearch('')
          setDate('')
        })
      }
    }else{
      if(currentPreInspectionPage <= totalPages[totalPages.length - 1] - value){
        setLoading(true)
        temp = currentPreInspectionPage + value
        setCurrentPreInspectionPage(temp)
        Promise.all([getInspectionData(temp), getTotalClientInspectionCount(temp)]).then((data)=>{
          setLoading(false)
          setSearch('')
          setAgentSearch('')
          setDate('')
        })
      }
    }
  }

  // function sets remark and save to db
  const sendRemarkData = async () => {

    const res = await makeAuthRequest(`${urlLink}incremental_comment/update`, {license: license, comment: remark, client_id: Cookies.get('client'), client_type: Cookies.get('clientType'), tl_id: Cookies.get("tlId")})

    license=""
    setRemark("")

    if(res.ok){
      setShowRemarkModal(false)
      getInspectionData(currentPreInspectionPage)
    }

  }

  //function download inspection report
  const downaloadReport = async() => {
    if(reportMonth === 0 || reportDownloading) return
    setReportDownloading(true)
    setTimeout(() => {
      setReportDownloading(false)
    }, 8000);
    const res = await makeAuthRequest(`${urlLink}download/csv`, {client_id: Cookies.get('client'), client_type: Cookies.get('clientType'), month: reportMonth, year: new Date().getMonth() < reportMonth - 1 ? new Date().getFullYear() - 1 : new Date().getFullYear()})
    if(res.ok){
      const data = await res.text()
      setReportDownloading(false)
      const url = window.URL.createObjectURL(new Blob([data])) 
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', "report.csv")
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  }

  const localTime = (time) => {
    let Months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    let dateTime = time.split(", ")
    let mon = Months.indexOf(dateTime[0].split(" ")[1])
    let local = new Date(Date.UTC(dateTime[0].split(" ")[2], mon, dateTime[0].split(" ")[0], dateTime[1].split(":")[0], dateTime[1].split(":")[1]))
    let Dte = local.getDate()
    let Month = Months[local.getMonth()]
    let year = local.getFullYear()
    let hour = local.getHours() % 12 ? local.getHours() % 12 >= 10 ? local.getHours() % 12 : `0${local.getHours() % 12}` : 12 
    let minute = local.getMinutes() >= 10 ? local.getMinutes() : `0${local.getMinutes()}` 
    let ampm = local.getHours() >= 12 ? 'PM' : 'AM'

    return `${Dte} ${Month} ${year}, ${hour}:${minute} ${ampm}`
  }

  const severityColor = (value) => {
    if(value == 0) return "green"
    if(value > 0 && value <= 5) return "#b59f26"
    if(value > 5 && value <= 50) return "#FF7F50"
    if(value > 50 && value <= 75) return "red"
    if(value > 75) return "darkred"
    }

    const getRadius = (value) => {
        return `${565.2 * ((100 - value)/100)}px`
    }

  useEffect(()=>{
    setLoading(true)
    setReportDownloading(false)
    setReportMonth(new Date().getMonth() + 1)
    if(!checkCookies()){
      removeCookies()
      navigate('/login')
    }
    else {
      setLanguage(Cookies.get('current-language'))
      setModule(JSON.parse(Cookies.get('modules')))
      //update language list here according to selected language*****(remove comment after implementing language json)
      
      setLanguageList(JSON.parse(Cookies.get('all-languages')))
      if(!searchInspection){
        Promise.all([getInspectionData(currentPreInspectionPage), getTotalClientInspectionCount(currentPreInspectionPage)]).
        then((data)=>{
          setLoading(false)
          setSearch('')
          setAgentSearch('')
          setDate('')
        })
      }
    }
  },[navigate, setLoading, setSearch, setAgentSearch, setDate, setModule, setLanguage, setLanguageList])

  return (
    <div className={styles.mainContainer}>
    <Navbar />
    <IncrementalControlBar/>
    {loading ? 
      <div className={styles.loading} >
        <img src={loadingImg} alt='loading...'/>
      </div>: 
      <div className={styles.mainSubContainer}>
        <div className={styles.InspectionHeader}>
          <div className={styles.InspectionTitle}>
            <h2>Incremental Damage Module</h2>
          </div>
          <div className={styles.pagination}>
            <i className="fa-solid fa-angles-left" style={{backgroundColor: currentPreInspectionPage <= 2 ? '#005baa' : '#157AFF'}} onClick={()=>onChangePage('left', 2)}></i>
            <i className="fa-solid fa-angle-left" style={{backgroundColor: currentPreInspectionPage === 1 ? '#005baa' : '#157AFF'}} onClick={()=>onChangePage('left', 1)}></i>

            {totalPages.map((item, index)=>{
              return <h4 key={index} style={{backgroundColor: currentPreInspectionPage === item ? '#157AFF' : null, 
              color: currentPreInspectionPage === item ? 'white' : null}} onClick={()=>{
                setLoading(true)
                setCurrentPreInspectionPage(item)
                Promise.all([getInspectionData(item), getTotalClientInspectionCount(item)]).then((data)=>{
                  setLoading(false)
                  setSearch('')
                  setAgentSearch('')
                  setDate('')
                })
              }}>{item}</h4>
            })}

            <i className="fa-solid fa-angle-right" style={{backgroundColor: currentPreInspectionPage + 1 > totalPages[totalPages.length - 1]  ? '#005baa' : '#157AFF'}}  onClick={()=>onChangePage('right', 1)}></i>
            <i className="fa-solid fa-angles-right" style={{backgroundColor: currentPreInspectionPage + 2 > totalPages[totalPages.length - 1]  ? '#005baa' : '#157AFF'}}  onClick={()=>onChangePage('right', 2)}></i>
          </div>
          <div className={styles.reportDownload} style={{visibility: "hidden"}}>
              <select className={styles.monthList} value={reportMonth} onChange={(e)=>setReportMonth(e.target.value)}>
                <option value={0}>{lang["Select Month"][language]}</option>
                
                {shortMonths.map((item, index) => {
                    if (11 - index <= new Date().getMonth()){
                      return <option key={index} value={12 - index}>{item}-{new Date().getMonth() < 11 - index ? (new Date().getFullYear() - 1) % 100 : new Date().getFullYear() % 100} </option>
                    }
                })}
                {shortMonths.map((item, index) => {
                    if (11 - index > new Date().getMonth()){
                      return <option key={index} value={12 - index}>{item}-{new Date().getMonth() < 11 - index ? (new Date().getFullYear() - 1) % 100 : new Date().getFullYear() % 100} </option>
                    }
                })}
              </select>
              <button className={styles.ReportDownloadBtn} style={reportDownloading ? {background: "#D9DEE9"} : {background: "#157AFF"}} onClick={()=>downaloadReport()}>{reportDownloading ? <img className={styles.reportDownloadLoading} src={loadingImg}></img> : lang["Download Report"][language]}</button>
          </div>
        </div>
        
        <div className={styles.InspectionTable}>
          <table className={styles.Table}>
            <thead>
              <tr>
                <th className={styles.InspectionTableHead}>Licence Plate</th>
                <th className={styles.InspectionTableHead}>Make</th>
                <th className={styles.InspectionTableHead}>Model</th>
                <th className={styles.InspectionTableHead}>Last Inspection Date</th>
                <th className={styles.InspectionTableHead}>KM's</th>
                <th className={styles.InspectionTableHead}>Car Condition</th>
                <th className={styles.InspectionTableHead}>Repair Workflow</th>
                <th className={styles.InspectionTableHead}>Severity</th>
                <th className={styles.InspectionTableHead}>Agent Name</th>
                <th className={styles.InspectionTableHead}>Team Lead ID</th>
                <th className={styles.InspectionTableHead}>Remark</th>
              </tr>
            </thead>
            <tbody>
            {incrementalList.map((row,i) => <tr key={i} onClick={ () => {} }>
                <td>
                    <div className={styles.vin}>
                        <img src={row.car_image ? row.car_image : car} alt='car'></img>
                        <span onClick={()=>navigate(`/incremental-inspection-list/${row.license}`)}>{row.license.toUpperCase()}</span>
                    </div>
                </td>
                <td>{row.make ? row.make : "---"}</td>
                <td>{row.model ? row.model : "---"}</td>
                <td>{row.last_inspection_date}</td>
                <td>{row.odo}</td>
                <td>{row.car_condition ? row.car_condition : "---"}</td>
                <td>{row.repair_workflow ? row.repair_workflow : "---"}</td>
                <td>{row.severity ? 
                    <svg width="60" height="60" viewBox="-25 -25 250 250" version="1.1" xmlns="http://www.w3.org/2000/svg" style={{transform: "rotate(-90deg)"}}>
                        <circle r="90" cx="100" cy="100" fill="transparent" stroke="#e0e0e0" stroke-width="20px" stroke-dasharray="565.48px" stroke-dashoffset="0"></circle>
                        <circle r="90" cx="100" cy="100" stroke={severityColor(parseInt(row.severity))} stroke-width="20px" stroke-linecap="round" stroke-dashoffset={getRadius(parseInt(row.severity))} fill="transparent" stroke-dasharray="565.2px"></circle>
                        <text x={parseInt(row.severity) === 100 ? "59px" : parseInt(row.severity) >= 10 ? "73px": "86px"} y="117px" fill={severityColor(parseInt(row.severity))} font-size="52px" font-weight="bold" style={{transform:"rotate(90deg) translate(0px, -196px"}}>{Math.floor(row.severity)}</text>
                    </svg> : "---"}</td>
                <td>{row.agent_name ? row.agent_name : "---"}</td>
                <td>{row.tl_id ? row.tl_id : "---"}</td>
                <td><div className={styles.Remark}><p>{row.comments ? row.comments : ""}</p> <img src={Edit} alt='edit' onClick={()=> {
                  setShowRemarkModal(true)
                  license = row.license
                  if(row.comments) setRemark(row.comments)
                }}/><img className={styles.InspectionNext} onClick={()=>navigate(`/incremental-inspection-list/${row.license}`)} src={nextBtn} alt=''/></div>
                </td>
            </tr>)}
            </tbody>
          </table>
        </div>
        {/* <div className={styles.Footer}> 
            <img src={logoFooter} alt='logo' /> 
            <p>{lang["Copyright"][language]}</p>
        </div> */}
        {showRemarkModal && <div className={styles.remarkModalContainer}>
          <div className={styles.remarkModal}>
          <h3>{lang["Add Remark"][language]}</h3>
            <textarea
              value = {remark}
              onChange={(e)=> setRemark(e.target.value)}
              className={styles.remarkInput}
            >
            </textarea>
            <div className={styles.remarkModalButtonContainer}>
              <div onClick={()=>sendRemarkData()}>{lang["Save"][language]}</div>
              <div onClick={()=>{
                setShowRemarkModal(false)
                license = ""
                setRemark("")
              }}>{lang["Close"][language]}</div>
            </div>
          </div>
        </div>}
      </div>}
      {/* <div className={styles.ScrollImageContainer}>
        <img src={rightArrowAngle}></img>
        <p>Scroll</p>
        <p>Right</p>
      </div> */}
    </div>
  )
}

export default IncrementalDamage