import React, { useContext, useEffect, useRef }  from 'react'
import { useNavigate } from 'react-router-dom';
import Car from '../../img/car.png'
import { AppContext } from '../context';
import './Login.scss';
import LoginNavbar from './LoginNavbar';
import DownArrow from '../../img/down.png';
import { makeRequest } from '../context/utils';
import Cookies from 'js-cookie'
import config from '../../config/config.json'

const Login = () => {
  const {urlLink} = config
  const { username, password, setUsername, setPassword, selectOpen, setSelectOpen, role, setRole, setLanguage, setModule, loginError, setLoginError, setIsMahindraClient } = useContext(AppContext)
  
  //User types to be sent with authentication data for filter (add new roles/users here in the list below)
  const userType = {"Agent": "agent", "Master": "master", "Team Lead": "team_lead"}

  const navigate = useNavigate()
  const clickRef = useRef()
  const listRef = useRef()

  //Submitting data to backend for authentication
  const handleSubmit = async() => {
    const res = await makeRequest(`${urlLink}login`, {client_id: username, password, client_type: Object.keys(userType).find(item => userType[item] === role)})
    const data = await res.json()
    
    if(data.status === "error"){
      setLoginError("Incorrect/Missing credentials")
      setUsername("")
      setPassword("")
      setTimeout(()=>setLoginError(""), 5000)
      return
    }

    //Setting cookies for further use 
    Cookies.set('all-languages', JSON.stringify(data.language), {expires: 0.16})
    Cookies.set('current-language', data.language[0], {expires: 0.16})
    Cookies.set('modules', JSON.stringify(data.modules_enabled), {expires: 0.16})
    Cookies.set('token', data.token, {expires: 0.16})
    Cookies.set('loggedIn', true, {expires: 0.16})
    Cookies.set('client', username, {expires: 0.16})
    Cookies.set('clientType', role, {expires: 0.16})
    Cookies.set('master', data.master_id, {expires: 0.16})

    Cookies.set('tlView', data.agent_tl_view, {expires: 0.16})
    Cookies.set('tlId', data.agent_tl_id, {expires: 0.16})

    //Initializing values
    setLanguage(data.language[0])
    setModule(data.modules_enabled)

    //Navigating to modules page after initialization and authentication
    setPassword("")
    navigate('/')
    
  }

  const toggleDropdown = () => {
    setSelectOpen(!selectOpen)
  }

  const handleSelect = (value) => {
    setRole(value)
    setSelectOpen(false)
  }

  useEffect(()=>{
    const handleClick = (event) => {
      if((clickRef.current && !clickRef.current.contains(event.target)) && (listRef.current && !listRef.current.contains(event.target))){
        if(selectOpen) return setSelectOpen(!selectOpen)
      }
    }

    document.addEventListener("mousedown", handleClick)

    let loc = window.location.search
    if(!loc) return
    else{
      let info = new URLSearchParams(loc)
      if(info.has("is_mahindra")){
        if(!info.has("access_token") || !info.has("master_id") || !info.has("client_id") || !info.has("client_type")|| !info.has("mahindra_region_id")) return navigate('/login')
        Cookies.set('token', info.get("access_token"), {expires: 0.16})
        Cookies.set('master', info.get("master_id"), {expires: 0.16})
        Cookies.set('all-languages', JSON.stringify(["English"]), {expires: 0.16})
        Cookies.set('current-language', "English", {expires: 0.16})
        Cookies.set('modules', JSON.stringify([]), {expires: 0.16})
        Cookies.set('loggedIn', true, {expires: 0.16})
        Cookies.set('client', info.get("client_id"), {expires: 0.16})
        Cookies.set('clientType', info.get("client_type"), {expires: 0.16})

        Cookies.set('isMahindra', "true", {expires: 0.16})
        Cookies.set('MahindraClientType', info.get("mahindra_client_type") ? info.get("mahindra_client_type") : "", {expires: 0.16})
        Cookies.set('MahindraDisplayName', info.get('mahindra_display_name') !== "0" ? info.get('mahindra_display_name') : "", {expires: 0.16})
        Cookies.set('MahindraRegionId', info.get("mahindra_region_id") !== "0" ? info.get("mahindra_region_id") : "", {expires: 0.16})
        Cookies.set('MahindraRegionName', info.get("mahindra_region_name") !== "0" ? info.get("mahindra_region_name") : "", {expires: 0.16})
        Cookies.set('MahindraTlId', info.get('mahindra_tl_id') !== "0" ? info.get('mahindra_tl_id') : "", {expires: 0.16})
        Cookies.set('MahindraTlName', info.get('mahindra_tl_name') !== "0" ? info.get('mahindra_tl_name') : "", {expires: 0.16})
        Cookies.set('MahindraUserId', info.get('mahindra_user_id') !== "0" ? info.get('mahindra_user_id') : "", {expires: 0.16})
        Cookies.set('MahindraUserName', info.get("mahindra_user_name") !== "0" ? info.get("mahindra_user_name") : "", {expires: 0.16})
        Cookies.set('MahindraLocationId', info.get("location_id") !== "0" ? info.get("location_id") : "", {expires: 0.16})
        Cookies.set('MahindraLocationName', info.get("location_name") !== "0" ? info.get("location_name") : "", {expires: 0.16})
        setIsMahindraClient(true)
        navigate('/vehicle-listing')
      }

      else{
        if(!info.has("authToken") || !info.has("master_id") || !info.has("language") || !info.has("modules_enabled") || !info.has("client_id") || !info.has("client_type")) return navigate('/login')
        Cookies.set('token', info.get("authToken"), {expires: 0.16})
        Cookies.set('master', info.get("master_id"), {expires: 0.16})
        Cookies.set('all-languages', JSON.stringify(info.get("language").split(",")), {expires: 0.16})
        Cookies.set('current-language', info.get("language").split(",")[0], {expires: 0.16})
        Cookies.set('modules', JSON.stringify(info.get("modules_enabled").split(",")), {expires: 0.16})
        Cookies.set('loggedIn', true, {expires: 0.16})
        Cookies.set('client', info.get("client_id"), {expires: 0.16})
        Cookies.set('clientType', info.get("client_type"), {expires: 0.16})

        Cookies.set('tlView', info.get("tlView") === '0' ? "" : info.get("tlView"), {expires: 0.16})
        Cookies.set('tlId', info.get("tlId") === '0' ? "" : info.get("tlId"), {expires: 0.16})
  
        setLanguage(info.get("language").split(",")[0])
        setModule(info.get("modules_enabled").split(","))
  
        navigate('/') 
      }
    }

    return () => document.removeEventListener("mousedown", handleClick)
    
  },[setSelectOpen, selectOpen])

return (
    <div className="main">
        <LoginNavbar />
        <div className="login-content">
          <div className="login-title">
            <p className="title">
            {window.top.location.host === "results.octotelematics.com" ? "Automatizza le ispezioni con l’intelligenza artificiale" : "Automate Inspections with AI"}
            </p>
            <p className="description">{window.top.location.host === "resultsjpn.octotelematics.com" ? "当社製品は、写真とビデオを使って損傷を検出するため、物理的な検査が不要になります。" : window.top.location.host === "results.octotelematics.com" ? "Il nostro prodotto individua danni su foto e video, eliminando il bisogno di un’ispezione fisica" : "Our products detect damages on items using photos & videos, eliminating the need for physical inspections"}</p>
          </div>
          <div className="login-section">
            <p>{window.top.location.host === "resultsjpn.octotelematics.com" ? "ログイン" : window.top.location.host === "results.octotelematics.com" ? "Accedi" : "Login"}</p>
            <div className="Select-type">
              <div
                className="Select-header"
                onClick={toggleDropdown}
                role="button"
                ref={clickRef}
              >
                {Object.keys(userType).find(item => userType[item] === role)}
                <img src={DownArrow} style={{transform: selectOpen ? 'rotate(180deg)' : "rotate(0deg)"}} alt="Down Arrow"/>
              </div>
              <ul ref={listRef} style={{display: selectOpen ? "block" : "none" }} className="Select-list">
              {Object.keys(userType).map((item,index) => (
                <li
                  className="Select-item"
                   key={index}
                  onClick={() => handleSelect(userType[item])}
                >
                {item}
                </li>
                ))}
              </ul>
            </div>
            <input
              className='formControl'
              placeholder='Client ID'
              type="text"
              id="username"
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
            <input
              className='formControl'
              placeholder='Password'
              type="password"
              id="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            {loginError && <h5 style={{color: "red"}}>{loginError}</h5>}
          </div>
            <div className="car-grad">
              <button className="btn" onClick={handleSubmit} type="submit">{window.top.location.host === "results.octotelematics.com" ? "Inizia" : "Start"}</button>
              <img src={Car} alt="car"/>
            </div>
        </div>
         <div className="copy-right">{window.top.location.host === "webapp.inspek.ai" ? "Copyright 2024 SAWITT FZCO All Right Reserved" : window.top.location.host === "resultsjpn.octotelematics.com" ? "" : window.top.location.host === "results.autosnaply.com" ? "" : window.top.location.host === "results.octotelematics.com" ? "" : window.top.location.host === "inspection-vn.iglooinsure.com" ? "" : window.top.location.host === "results.easydetect.ai" ? "© Copyright 2023 easydetect.  All rights reserved." :"© Copyright 2023 Inspektlabs Inc. All rights reserved."}</div> 
    </div>
  )
}

export default Login