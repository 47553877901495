import React,{ useContext, useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import 'react-responsive-modal'
import { Modal } from 'react-responsive-modal';
import { AppContext } from './context';

import GoBack from '../img/left-arrow.png';
import Navbar from './Navbar'
import styles from './style/GenereteUrl.module.scss'
import Next from '../img/blueNext.png'
import URL from '../img/genrateUrl.svg'
import logo from '../img/logo.svg'
import pen from '../img/edit.png'
import rightArrowAngle from '../img/right-arrow-angle.png' 
import saveTick from '../img/saveTick.png'
import forward from '../img/forward.png'
import loadingImg from '../img/loading.gif'
import GenerateLink from './GenerateLink';
import Cookies from 'js-cookie';
import { checkCookies, makeAuthRequest, removeCookies } from './context/utils';
import lang from './language/GenerateUrl.json'
import config from '../config/config.json'

let sendingData = false
const GenerateUrl = () => {
  const {urlLink, octoClients} = config


  const { open, setOpen, urlgenList, setUrlgenList, totalUrlgenPages, setTotalUrlgenPages, currentUrlgenPage, setCurrentUrlgenPage, urlgenLoading, setUrlgenLoading,
          urlgenSearch, setUrlgenSearch, urlgenDate, setUrlgenDate, setLanguage, setLanguageList, setModule, language, setSearchInspection, setPersonaList } = useContext(AppContext);

  const onOpenModal = () => { 
    setOpen(true);
  }

  const [editModal, setEditModal] = useState(false)
  const [editEmailModalArray, setEditEmailModalArray] = useState([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false])
  const [editPhoneModalArray, setEditPhoneModalArray] = useState([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false])
  const [emailUpdated, setEmailUpdated] = useState([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false])
  const [phoneUpdated, setPhoneUpdated] = useState([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false])
  const [phoneArray, setPhoneArray]= useState(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''])
  const [emailArray, setEmailArray]= useState(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''])
  const [sentData, setSentData] = useState({})
  const tableRef = useRef()

  const navigate = useNavigate();

  const getUrlgenData = (page) => {

    return new Promise((resolve, reject) => {

      // limit: number of urlgen data on a single page, page_no: page number displayed on screen
      makeAuthRequest(`${urlLink}urlgen-record`, {client_id: Cookies.get('client'), limit: 20, page_no: page, client_type: Cookies.get('clientType'), agent_tl_view: Cookies.get("tlView"), agent_tl_id: Cookies.get("tlId"), master_id: Cookies.get("master")})
      .then((res)=> res.json()
      .then((data)=>{

      //setting urlgen list as a state to be shown on screen
      setUrlgenList(data)
      resolve({status: true})

      })).catch((error)=>{
        console.log(error)
        reject("Error during requesting inspection data")
      })
    })
  }

  //funtion returns and sets total pages to be displayed on screen
  const getTotalClientUrlgenCount = async (page) => {

    return new Promise((resolve, reject)=>{

      makeAuthRequest(`${urlLink}count-inspection-urlgen`, {client_id: Cookies.get('client'), client_type: Cookies.get('clientType'), agent_tl_view: Cookies.get("tlView"), agent_tl_id: Cookies.get("tlId"), master_id: Cookies.get("master")})
      .then((res)=> res.json()
      .then((data)=>{

        //limiting page count to 10
        let limit = data.count
        if (limit === 0){
          return resolve({status: true})
        }  
        if(data.count > 200){
          limit = 200
        }
        let pageLimit = Math.ceil(limit / 20)
        let temp = []
        for(let i = 1; i <= pageLimit; i++){
          temp.push(i)
        }
        setTotalUrlgenPages(temp)
        setCurrentUrlgenPage(page)
        resolve({status: true})

      })).catch((error)=>{
        console.log(error)
        reject("Error during requesting inspection count")
      })
    })
  }

  //function controls pagination arrows
  const onChangePage = (direction, value) => {
    let temp = 0
    if(direction === 'left'){
      if(currentUrlgenPage > value){
        setUrlgenLoading(true)
        temp = currentUrlgenPage - value
        setCurrentUrlgenPage(temp)
        Promise.all([getUrlgenData(temp), getTotalClientUrlgenCount(temp)]).then((data)=>{
          setUrlgenLoading(false)
          setUrlgenSearch('')
          setUrlgenDate('')
        })
      }
    }else{
      if(currentUrlgenPage <= totalUrlgenPages[totalUrlgenPages.length - 1] - value){
        setUrlgenLoading(true)
        temp = currentUrlgenPage + value
        setCurrentUrlgenPage(temp)
        Promise.all([getUrlgenData(temp), getTotalClientUrlgenCount(temp)]).then((data)=>{
          setUrlgenLoading(false)
          setUrlgenSearch('')
          setUrlgenDate('')
        })
      }
    }
    setEditEmailModalArray([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false])
    setEditPhoneModalArray([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false])
    setEmailUpdated([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false])
    setPhoneUpdated([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false])
    setEmailArray(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''])
    setPhoneArray(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''])
  }

  //function gets filtered result from backend by sending date/case/inspection/licence
  const getFilteredResult = async (type, value) => {
    if(type === 'date') setUrlgenDate(value)
    else setUrlgenSearch(value)

    if(value === '') return getUrlgenData(currentUrlgenPage)

    if(value.length < 5) return

    const res = await makeAuthRequest(`${urlLink}search-value-urlgen`, {search_value: value, search_type: type, client_id: Cookies.get('client'), client_type: Cookies.get('clientType'), agent_tl_view: Cookies.get("tlView"), agent_tl_id: Cookies.get("tlId"), master_id: Cookies.get("master")})
    const data = await res.json()
    setUrlgenList(data)
  }

  //converting timeStamp to date/time format
  const dateTime = (timestamp) => {
    const time = new Date(timestamp)
    const month = ["January","February","March","April","May","June","July","August","September","October","November","December"]
    let minutes = time.getMinutes() < 10 ? `0${time.getMinutes()}`: time.getMinutes()
    let hours = time.getHours() < 10 ? `0${time.getHours()}`: time.getHours()
    return time.getDate()+
           " "+month[time.getMonth()]+
           " "+time.getFullYear()+
           " "+hours+
           ":"+minutes
  }

  const openInspection = (inspection) => {
    setSearchInspection(inspection)
    navigate(`/pre-inspection`)
  }

  const getPersonaList = async() => {
    const res = await makeAuthRequest(`${urlLink}existingPersonaId`, {client_id: Cookies.get('master'), client_type: Cookies.get('clientType')})
    const data = await res.json()
    if(data.list.length > 0){
      setPersonaList(data.list)
      console.log(data.list)
    }
  }

  const sendData = async(type, email, phone, personaId, licensePlate, caseId, token, i) => {
    if(sendingData) return
    alert((emailUpdated[i] && phoneUpdated[i]) ? `${lang["Resending Email and SMS"][language]} ...` : emailUpdated[i] ? `${lang["Resending Email"][language]} ...` : phoneUpdated[i] ? `${lang["Resending SMS"][language]} ...` : `${lang["Resending Email and SMS"][language]} ...`)
    sendingData = true
    const res = type === "edit" ? await makeAuthRequest(`${urlLink}feed-token-data-urlgen`, {client_id: Cookies.get('client'), phone: phone, email: email, final_count: 0, generation_time: Date.now(), 
    expiration_time: Date.now() + (43200*60000), persona_id: personaId, source: 'Super', link_type: "single", app_id: 0, license_plate: licensePlate, repeat: false, case_id: caseId, app_type: "custom", 
    client_type: Cookies.get('clientType'), send_comm_via_api: ["client_507", "client_1217", "client_1121"].includes(Cookies.get('master')), update_details: 1, resend: "", existing_token: token}) : 
    
    await makeAuthRequest(`${urlLink}feed-token-data-urlgen`, {client_id: Cookies.get('client'), phone: phone, email: email, final_count: 0, generation_time: Date.now(), 
    expiration_time: Date.now() + (43200*60000), persona_id: personaId, source: 'Super', link_type: "single", app_id: 0, license_plate: licensePlate, repeat: false, case_id: caseId, app_type: "custom", 
    client_type: Cookies.get('clientType'), send_comm_via_api: ["client_507", "client_1217", "client_1121"].includes(Cookies.get('master')), update_details: "", resend: 1, existing_token: token})
    const data = await res.json()
    setTimeout(() => {
      sendingData = false
    }, 3000);
    if(res.ok){
      alert((emailUpdated[i] && phoneUpdated[i]) ? "Email/SMS sent" : emailUpdated[i] ? "Email sent" : phoneUpdated[i] ? "SMS Sent" : "Email/SMS sent")
      setEditEmailModalArray(arr => {
        const newArray = [... arr]
        newArray[i] = false
        return newArray
      })
      setEditPhoneModalArray(arr => {
        const newArray = [... arr]
        newArray[i] = false
        return newArray
      })
      setEmailUpdated(arr => {
        const newArray = [... arr]
        newArray[i] = false
        return newArray
      })
      setPhoneUpdated(arr => {
        const newArray = [... arr]
        newArray[i] = false
        return newArray
      })
      getUrlgenData(currentUrlgenPage)
    }

  } 


  useEffect(()=>{
    setUrlgenLoading(true)
    if(!checkCookies()){
      removeCookies()
      navigate('/login')
    }
    else {
      setLanguage(Cookies.get('current-language'))
      setModule(JSON.parse(Cookies.get('modules')))
      //update language list here according to selected language*****(remove comment after implementing language json)
      
      setLanguageList(JSON.parse(Cookies.get('all-languages')))
      Promise.all([getUrlgenData(1), getTotalClientUrlgenCount(1)]).
      then((data)=>{
        setUrlgenLoading(false)
        setUrlgenSearch('')
        setUrlgenDate('')
      })
      getPersonaList()
    }
  },[navigate, setUrlgenLoading, setUrlgenSearch, setUrlgenDate, setLanguage, setLanguageList, setModule])

  return (
    <div className={styles.mainContainer}>
        <Navbar/>
          <nav className={styles.controlBar}>
            <div className={styles.controlBarContainer}>
              <div className="controlbar-left">
                <button onClick={ () => navigate('/') } className={styles.controlBarBackButton}>
                  <img  src={GoBack} alt="Back"  />
                  {lang["Back to Menu"][language]}
                </button>
              </div>
              <form className={styles.controlBarForm}>
                <label>{lang["Filter By"][language]}</label>
                <input
                  type="date"
                  value={urlgenDate}
                  onChange={(e) => {getFilteredResult('date', e.target.value)}}
                  placeholder={lang["date"][language]}
                  className={styles.controlBarInputDate}
                />
                <input
                  type="text"
                  value={urlgenSearch}
                  onChange={(e) => {getFilteredResult('inspection', e.target.value)}}
                  placeholder={lang["Inspection"][language]}
                  className={styles.controlBarInputId}
                />
              </form>
            </div>
          </nav>
          <div className={styles.Main}>
            {!urlgenLoading ? 
              <div className={styles.TableContainer}>
                <div className={styles.TableTitleContainer}>
                  <div className={styles.TableTitle}>
                    <h2 className={styles.h2}>{lang["URL-Generator"][language]}</h2>
                  </div>
                  <div className={styles.pagination}>
                    <i className="fa-solid fa-angles-left" style={{backgroundColor: currentUrlgenPage <= 2 ? '#005baa' : '#157AFF'}} onClick={()=>onChangePage('left', 2)}></i>
                    <i className="fa-solid fa-angle-left" style={{backgroundColor: currentUrlgenPage === 1 ? '#005baa' : '#157AFF'}} onClick={()=>onChangePage('left', 1)}></i>

                    {totalUrlgenPages.map((item, index)=>{
                      return <h4 key={index} style={{backgroundColor: currentUrlgenPage === item ? '#157AFF' : null, 
                      color: currentUrlgenPage === item ? 'white' : null}} onClick={()=>{
                        setUrlgenLoading(true)
                        setCurrentUrlgenPage(item)
                        Promise.all([getUrlgenData(item), getTotalClientUrlgenCount(item)]).then((data)=>{
                          setUrlgenLoading(false)
                          setUrlgenSearch('')
                          setUrlgenDate('')
                        })
                      }}>{item}</h4>
                    })}

                    <i className="fa-solid fa-angle-right" style={{backgroundColor: currentUrlgenPage + 1 > totalUrlgenPages[totalUrlgenPages.length - 1]  ? '#005baa' : '#157AFF'}}  onClick={()=>onChangePage('right', 1)}></i>
                    <i className="fa-solid fa-angles-right" style={{backgroundColor: currentUrlgenPage + 2 > totalUrlgenPages[totalUrlgenPages.length - 1]  ? '#005baa' : '#157AFF'}}  onClick={()=>onChangePage('right', 2)}></i>
                  </div>
                </div>
              <div className={styles.TableUrl} ref={tableRef}>
                <table>
                  <thead>
                    <tr>
                      <th>{lang["Case"][language]}</th>
                      <th>{lang["License Plate"][language]}</th>
                      <th>{lang["URL"][language]}</th>
                      {["dekra_nl_uat","dekra_nl_prod", "ss321gn"].includes(Cookies.get("master")) && <th>{lang["Email Address"][language]}</th>}
                      {["dekra_nl_uat","dekra_nl_prod", "ss321gn"].includes(Cookies.get("master")) && <th>{lang["Phone number"][language]}</th>}
                      {octoClients.includes(Cookies.get("master")) && <th>{lang["Agent Name"][language]}</th>}
                      <th>{lang["Generation date"][language]}</th>
                      <th>{lang["Expiration date"][language]}</th>
                      <th>{lang["Type of web app"][language]}</th>
                      <th>{lang["Status"][language]}</th>
                      <th>{lang["Expiry"][language]}</th>
                      <th>{lang["InspectionId"][language]}</th>
                      {["dekra_nl_uat","dekra_nl_prod", "ss321gn"].includes(Cookies.get("master")) && <th></th>}
                    </tr>
                  </thead>
                  <tbody>
                      {urlgenList.map((row,i) => <tr key={i}>
                          {row.caseId.length > 18 ? <td title='Copy to clipboard' onClick={()=>{ navigator.clipboard.writeText(row.caseId) }} style={{cursor: "pointer"}}>{row.caseId.slice(0,18)}...</td>: <td title='Copy to clipboard' onClick={()=>{ navigator.clipboard.writeText(row.caseId)}} style={{cursor: "pointer"}}>{row.caseId}</td>}
                          <td>{row.licensePlate}</td>
                          {`${row.appUrl}#${row.token}`.length > 16? <td title='Copy to clipboard' onClick={()=>{ navigator.clipboard.writeText(`${row.appUrl}#${row.token}`) }} style={{cursor: "pointer"}}>{`${row.appUrl}#${row.token}`.slice(0,16)}...</td>: <td title='Copy to clipboard' onClick={()=>{ navigator.clipboard.writeText(`${row.appUrl}#${row.token}`) }} style={{cursor: "pointer"}}>{`${row.appUrl}#${row.token}`}</td>}
                          {["dekra_nl_uat","dekra_nl_prod", "ss321gn"].includes(Cookies.get("master")) && <td>{editEmailModalArray[i] ? <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "10px"}}><input type='text' value={emailArray[i]} onChange={(e)=>setEmailArray(arr => {
                            const newArray = [...arr]
                            newArray[i] = e.target.value
                            return newArray
                          })}/><img style={{cursor: "pointer"}} onClick={()=>{
                            setEmailUpdated(arr=>{
                              const newArray = [...arr]
                              newArray[i] = true
                              return newArray
                            })
                            setEditEmailModalArray(arr=>{
                              const newArray = [...arr]
                              newArray[i] = false
                              return newArray
                            })
                            // sendData("edit", emailArray[i], phoneArray[i], row.personaId, row.licensePlate, row.caseId, row.token, i)
                          }} src={saveTick}></img></div> : <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "10px"}}>{emailUpdated[i] ? emailArray[i] : row.email}<img style={{cursor: "pointer"}} onClick={()=>{
                            setEmailArray(arr => {
                              const newArray = [... arr]
                              newArray[i] = row.email
                              return newArray
                            })
                            setPhoneArray(arr => {
                              const newArray = [... arr]
                              newArray[i] = row.phone
                              return newArray
                            })
                            setEditEmailModalArray(arr => {
                              const newArray = [... arr]
                              newArray[i] = true
                              return newArray
                            })
                          }} src={pen}></img></div>}</td>}

                          {["dekra_nl_uat","dekra_nl_prod", "ss321gn"].includes(Cookies.get("master")) && <td>{editPhoneModalArray[i] ? <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "10px"}}><input type='text' value={phoneArray[i]} onChange={(e)=>setPhoneArray(arr => {
                            const newArray = [...arr]
                            newArray[i] = e.target.value
                            return newArray
                          })}/><img style={{cursor: "pointer"}} onClick={()=>{
                            setPhoneUpdated(arr=>{
                              const newArray = [...arr]
                              newArray[i] = true
                              return newArray
                            })
                            setEditPhoneModalArray(arr=>{
                              const newArray = [...arr]
                              newArray[i] = false
                              return newArray
                            })
                            // sendData("edit", emailArray[i], phoneArray[i], row.personaId, row.licensePlate, row.caseId, row.token, i)
                          }} src={saveTick}></img></div> : <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "10px"}}>{phoneUpdated[i] ? phoneArray[i] : row.phone}<img style={{cursor: "pointer"}} onClick={()=>{
                            setEmailArray(arr => {
                              const newArray = [... arr]
                              newArray[i] = row.email
                              return newArray
                            })
                            setPhoneArray(arr => {
                              const newArray = [... arr]
                              newArray[i] = row.phone
                              return newArray
                            })
                            setEditPhoneModalArray(arr => {
                              const newArray = [... arr]
                              newArray[i] = true
                              return newArray
                            })
                          }} src={pen}></img></div>}</td>}
                          {octoClients.includes(Cookies.get("master")) && <td>{row.agentName ? row.agentName : "---"}</td>}
                          <td>{dateTime(row.date)}</td>
                          <td>{dateTime(row.expirationDate)}</td>
                          <td>{row.source} App {row.appId ? `(App ID: ${row.appId})`: `(Persona ID: ${row.personaId})`} </td>
                          <td>{row.used ? lang["Used"][language]: lang["Not Used"][language]}</td>
                          <td>{(row.expirationDate > Date.now()) ? lang["Not Expired"][language] : lang["Expired"][language]}</td>
                          <td className={styles.Trow} onClick={()=>openInspection(row.inspectionId)}>{row.inspectionId}</td>
                          {["dekra_nl_uat","dekra_nl_prod", "ss321gn"].includes(Cookies.get("master")) && <td><div onClick={()=> sendData((emailUpdated[i] || phoneUpdated[i]) ? "edit" : "resend", emailUpdated[i] ? emailArray[i]: row.email, phoneUpdated[i] ? phoneArray[i]: row.phone, row.personaId, row.licensePlate, row.caseId, row.token, i)} style={{width: "90px", height: "30px", borderRadius: "10px", padding: "7px", backgroundColor: "#157AFF", color: "white", cursor: "pointer"}}>Resend</div></td>}
                      </tr>)}
                    </tbody>
                  </table>
                </div>
              </div>:
              <div className={styles.loading} >
                <img src={loadingImg} alt='loading...'/>
              </div>
              }
              {!octoClients.includes(Cookies.get("master")) && <div className={styles.ScrollImageContainer}>
                <img onClick={()=>tableRef.current.scrollRight += 1200} src={rightArrowAngle}></img>
                <p>Scroll</p>
                <p>Right</p>
              </div>}
              <div className={styles.GenerateUrlBoxContainer} >
                <div className={styles.GenerateUrlBox} >
                  <img onClick={onOpenModal} style={{cursor:'pointer'}} src={URL} alt="ellipse" />
                  <p>{lang["Generate"][language]}</p>
                  <button type="button" onClick={onOpenModal}>{lang["Generate-URL"][language]}</button>
                  {open && <Modal  open={true} closeIcon={<></>} center>
                    <GenerateLink/>
                  </Modal>}
                  <img className={styles.Logo} src={config["logo"].includes(Cookies.get("master")) ? `/logo/${Cookies.get("master")}.svg` : logo} alt="logo" />
                </div>
              </div>
            </div>

          {editModal && 
            <div className={styles.remarkModalContainer}>
                <div className={styles.remarkModal} style={{height: "200px", width: "500px"}}>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "2em", flexDirection: "column"}}>
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "0.5em"}}>
                          <label htmlFor={lang["Email Address"][language]}>
                              {lang["Email Address"][language]}
                          </label>
                          <input
                          type='text'
                          value={email}
                          onChange={(e)=>setEmail(e.target.value)}
                          />
                        </div>
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "0.5em"}}>
                          <label htmlFor={lang["Phone number"][language]}>
                            {lang["Phone number"][language]}
                          </label>
                          <input
                          type='text'
                          value={phone}
                          onChange={(e)=>setPhone(e.target.value)}
                          />
                        </div>
                    </div>
                    <div className={styles.remarkModalButtonContainer}>
                        <div onClick={()=>sendData("edit")}>{lang["Submit"][language]}</div>
                        <div onClick={()=>{
                            setEditModal(false)
                            }}>{lang["Close"][language]}</div>
                    </div>
                </div>
            </div>
        }

    </div>
  )
}
export default GenerateUrl
