import React,{ useContext, useState } from 'react'
import BackBtn from '../img/backButtonBlue.svg'
import cross from '../img/cross.png'
import Line from '../img/bigHr.svg'
import Style from './style/DamageTypeModal.module.scss'
import Score from '../img/Score.svg'
import Length from '../img/lengthBlue.svg'
import { AppContext } from './context';
import lang from './language/DamageTypeModal.json'
import partDamage from './language/partDamage.json'


const DamageTypeModal = () => {
    const { setOpen, clickedPart, language, isHailDamage, isSizeOfDamage, isInternalDamage, photowiseDetails } = useContext(AppContext);

    const onCloseModal = () => setOpen(false);

    const [currentTab, setCurrentTab] = useState(isSizeOfDamage ? 1 : isInternalDamage ? 2 : isHailDamage ? 3 : 1)

    const convertPart = (part) => {
        let nameFound = false
        for(let i = 0; i <partDamage.length; i++){
            if(partDamage[i].English === part){
                if(Object.keys(partDamage[i]).includes(language)){
                    nameFound = true
                    return partDamage[i][language]
                }
            }
        }
        if(!nameFound) return part
    }

  return (
    <div className={Style.DamageType}>
        <div className={Style.background} onClick={()=>setOpen(false)}></div>
        <div className={Style.content}>
            <div className={Style.Title}>
                <img src={cross} onClick={onCloseModal} alt="backBtn"/>
            </div>
            <hr></hr>
            <div className={Style.mainContainer}>
                <div className={Style.tabContainer}>
                    {isSizeOfDamage && <div style={currentTab == 1 ? {backgroundColor: "#157AFF"} : {color: "black"}} className={Style.tabs} onClick={()=>setCurrentTab(1)}><p style={currentTab == 1 ? {color: "white"} : {color: "black"}}>{lang["Size of Damage"][language]}</p></div>}
                    {isInternalDamage && <div style={currentTab == 2 ? {backgroundColor: "#157AFF"} : {color: "black"}} className={Style.tabs} onClick={()=>setCurrentTab(2)}><p style={currentTab == 2 ? {color: "white"} : {color: "black"}}>{lang["Internal Damage"][language]}</p></div>}
                    {isHailDamage && <div style={currentTab == 3 ? {backgroundColor: "#157AFF"} : {color: "black"}} className={Style.tabs} onClick={()=>setCurrentTab(3)}><p style={currentTab == 3 ? {color: "white"} : {color: "black"}}>{lang["Hail Damage"][language]}</p></div>}
                </div>
                {(currentTab === 1) && <div className={Style.subContainer}>
                    <div className={Style.tableContainer}>
                        <table>
                            <thead>
                                <tr>
                                    <td>{lang["Part"][language]}</td>
                                    <td>{lang["Damage"][language]}</td>
                                    <td>{lang["Length"][language]}</td>
                                    <td>{lang["Breadth"][language]}</td>
                                    <td>{lang["Depth"][language]}</td>
                                </tr>
                            </thead>
                            <tbody>
                                
                                {Object.keys(photowiseDetails["unified_json"]["additionalFeatures"]["sizeOfDamage"]).map((item, index) => 
                                photowiseDetails["unified_json"]["additionalFeatures"]["sizeOfDamage"][item].map((item1, idx) => {
                                    return (<tr key={idx}>
                                                <td>{convertPart(item)}</td>
                                                <td>{convertPart(item1.damageName)}</td>
                                                <td>{item1.properties.height}</td>
                                                <td>{item1.properties.width}</td>
                                                <td>{item1.properties.depth}</td>
                                            </tr>)
                                }))}
                            </tbody>
                        </table>
                    </div>
                </div>}
                
                {(currentTab === 2) && <div className={Style.subContainer}>
                    <div className={Style.tableContainer}>
                        <table>
                            <thead>
                                <tr>
                                    <td>{lang["External Part"][language]}</td>
                                    <td>{lang["High Probability Internal Damage"][language]}</td>
                                    <td>{lang["Confidence Score"][language]}</td>
                                </tr>
                            </thead>
                            <tbody>
                                {photowiseDetails["unified_json"]["additionalFeatures"]["highProbabilityInternalDamages"].map((item, index) => 
                                <tr key={index}>
                                    <td>{convertPart(item.mainPart)}</td>
                                    <td>{convertPart(item.part)}</td>
                                    <td>{item.confidence}</td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>}
                {(currentTab === 3) ? <div className={Style.subContainer}>
                    <div className={Style.tableContainer}>
                        <table>
                            <thead>
                                <tr>
                                    <td>{lang["Part"][language]}</td>
                                    <td>{lang["Size"][language]}</td>
                                    <td>{lang["Number"][language]}</td>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(photowiseDetails["unified_json"]["additionalFeatures"]["hailDamage"]).map((item, index) => 
                                    photowiseDetails["unified_json"]["additionalFeatures"]["hailDamage"][item].map((item1, index1) => 
                                        {return (
                                            <tr key={`${index}-${index1}`}>
                                                <td>{convertPart(item)}</td>
                                                <td>{item1["sizeOfHailDamages"]}</td>
                                                <td>{item1["numberOfHailDamages"]}</td>
                                            </tr>
                                        )}
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>: null}
            </div>
        </div>
    </div>
  )
}

export default DamageTypeModal