import React, {useEffect} from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AppContext } from './component/context';
import './App.scss';
import Login from './component/login/Login';
import LoginGenerali from './component/login/LoginGenerali';
import LoginTata from './component/login/LoginTata';
import Module from './component/Module';
import PreInspection from './component/PreInspection';
import NewInspection from './component/NewInspection';
import GenerateUrl from './component/GenerateUrl';
import useAppData from './component/context/hooks';
import InspectionResult from './component/InspectionResult';
import RejectedResult from './component/RejectedResult';
import Claim from './component/Claim';
import ClaimResult from './component/ClaimResult';
import GTclaim from './component/GTclaim';
import GTclose from './component/GTclose';
import VinListing from './component/VinListing';
import FleetInspection from './component/FleetInspection';
import Dashboard from './component/Dashboard';
import IncrementalDamage from './component/IncrementalDamage';
import IncrementalVehicleList from './component/IncrementalVehicleList';

const App = () => {

  const appData = useAppData()

  useEffect(() => {
    if(window.top.location.host === "results.easydetect.ai") {
      document.title = "Easydetect"
    }
    if(window.top.location.host === "inspection-vn.iglooinsure.com") {
      document.title = "iglooinsure"

      const link = document.querySelector('link[rel="icon"]');

      if (link) {
        link.setAttribute('href', 'favicon-igloo.ico');
      }
    }

    if(window.top.location.host === "results.octotelematics.com") {
      document.title = "octotelematics"

      const link = document.querySelector('link[rel="icon"]');

      if (link) {
        link.setAttribute('href', 'octo.ico');
      }
    }

    if(window.top.location.host === "resultsjpn.octotelematics.com") {
      document.title = "octotelematics"

      const link = document.querySelector('link[rel="icon"]');

      if (link) {
        link.setAttribute('href', 'octo.ico');
      }
    }

    if(window.top.location.host === "results.autosnaply.com") {
      document.title = "autosnaply"

      const link = document.querySelector('link[rel="icon"]');

      if (link) {
        link.setAttribute('href', 'client_1255.ico');
      }
    }

    if(window.top.location.host === "webapp.inspek.ai") {
      document.title = "inspek.ai"

      const link = document.querySelector('link[rel="icon"]');

      if (link) {
        link.setAttribute('href', 'sawitt.ico');
      }
    }
  },[])
  
  return (
    <AppContext.Provider value={appData}>
      <BrowserRouter>
        <Routes>
          <>
            <Route path="/login" element={<Login/>}/>
            <Route path="/generali-login" element={<LoginGenerali/>}/>
            <Route path="/tata-digital-login" element={<LoginTata/>}/>
            <Route path="/" element={<Module/>}/>
            <Route path="/url-generator" element={<GenerateUrl />}/>
            <Route path="/pre-inspection" element={<PreInspection />}/>
            <Route path="/claim" element={<Claim />}/>
            <Route path="/newInspection" element={<NewInspection/>}/>
            <Route path="/pre-inspection/:id" element={<InspectionResult />}/>
            <Route path="/rejected/:id" element={<RejectedResult />}/>
            <Route path="/claim/:id" element={<ClaimResult />}/>
            <Route path="/estimate/:id" element={<GTclaim />}/>
            <Route path="/close-gt-estimate/:id" element={<GTclose />}/>
            <Route path="/vehicle-listing" element={<VinListing />}/>
            <Route path="/vehicle-inspection-list/:id" element={<FleetInspection />}/>
            <Route path="/dashboard" element={<Dashboard />}/>
            <Route path="/incremental-damage" element={<IncrementalDamage />}/>
            <Route path="/incremental-inspection-list/:id" element={<IncrementalVehicleList />}/>
          </>
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;
