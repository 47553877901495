import { createContext } from "react";

export const AppContext = createContext({
    username: "",
    password: "",
    language: "",
    languageList: [],
    loggedIn: false,
    module: [],
    isOpen: false,
    isProfileOpen: false,
    isNavProfileOpen: false,
    open: false,
    swipe: "carLevelResult",
    openMenu: false,
    role: "agent",
    selectOpen: false,
    preInspectionList: [],
    currentPreInspectionPage: 1,
    totalPages: [],
    loading: true,
    date: '',
    search: '',
    agentSearch: '',
    showRemarkModal: false,
    remark: '',
    webappType: "single",
    urlgenList: [],
    totalUrlgenPages: [],
    currentUrlgenPage: 1,
    urlgenLoading: true,
    urlgenSearch: '',
    urlgenDate: '',
    appTypeOpen: false,
    linkType: "custom",
    navLaunguageOpen: false,
    webAppEmail: "",
    webAppPhone: "",
    webAppFinalCount: "",
    webAppPersonaId: "",
    webAppId: "",
    webAppLicencePlate: "",
    webappRepeat: false,
    webAppCaseID: "",
    photowiseDetails: {},
    photowiseLoading: true,
    agentApprove: "Disapproved",
    selectedImage: {},
    policyNumber: "",
    approvalType: "",
    showPolicyModal: false,
    policyEmail: "",
    policyLoading: "loading",
    showApproveModal: false,
    clickedPart : 0,
    searchInspection: "",
    openImageModal: false,
    imageList:[],
    urlList: [],
    showImageError: "",
    openImageSuccessModal: false,
    urlGenerating: false,
    loginError: "",
    urlSubmitted: true,
    showCarRemarkModal: false,
    carRemark: "",
    countryCode: "",
    configPresent: false,
    clientType: "",
    isGTEstimateActive: false,
    openGTResponseModal: false,
    GTResponseMessage: "",
    GTUrl: "",
    licensePlate: "",
    make: "",
    model: "",
    odometer: "",
    vin: "",
    fuelmeter: "",
    showVinModal: false,
    sogesaLoading: "loading",
    showSogesaModal: false,
    infoCarCode: "",
    personaList: [],
    reportMonth: 1,
    reportDownloading: false,
    configData: {},
    editMode: false,
    carLevelEditData: {},
    partLevelEditData: [],
    partCheckBox: [],
    editRow: false,
    editedData: [],
    isMahindraClient: false,
    allowDamageModal: false,
    isHailDamage: false,
    isSizeOfDamage: false,
    isInternalDamage: false,
    incrementalList: [],
    incrementalSearchInspection: "",
    incrementalSearchDate: "",
    incrementalSearchCarCondtion: "",
    incrementalInspectionData: [],
    incrementalInspectionProcessingData: [],
    incrementalInspectionAvailableData: [],
    incrementalInspectionRejectedData: [],
    incrementalInspectionLoading: true
})