import React, {useContext, useEffect} from 'react';
import GoBack from '../img/left-arrow.png';
import { AppContext } from './context';
import { makeAuthRequest } from './context/utils';
import Cookies from 'js-cookie';
import styles from './style/Navbar.module.scss';
import lang from './language/Controlbar.json'
import config from '../config/config.json'
import { useNavigate, useParams } from 'react-router-dom'

const IncrementalVehicleListControlBar = () => {
    const { incrementalSearchInspection, setIncrementalSearchInspection, incrementalSearchDate, setIncrementalSearchDate, incrementalSearchCarCondtion, setIncrementalSearchCarCondtion, incrementalInspectionProcessingData, setIncrementalInspectionProcessingData, incrementalInspectionData, setIncrementalInspectionData,
    incrementalInspectionAvailableData, setIncrementalInspectionAvailableData, incrementalInspectionRejectedData, setIncrementalInspectionRejectedData, incrementalInspectionLoading, setIncrementalInspectionLoading, language } = useContext(AppContext)

    let navigate = useNavigate()
    const {urlLink, octoClients} = config
    const {id} = useParams()

  //function gets filtered result from backend by sending date/case/inspection/licence
  const getFilteredResult = async (type, value) => {
    if(type === 'date') setIncrementalSearchDate(value)
    else if (type === 'car_condition') setIncrementalSearchCarCondtion(value)
    else setIncrementalSearchInspection(value)

    if(value === '') return getData()

    if(value.length < 5) return

    const res = await makeAuthRequest(`${urlLink}incremental-inspections-filter`, {search_value: value, search_type: type, client_id: Cookies.get('client'), client_type: Cookies.get('clientType'), agent_id: "", tl_id: Cookies.get("tlId"), license : id,})
    const data = await res.json()
    setIncrementalInspectionProcessingData(data.processing)
    setIncrementalInspectionAvailableData(data.available)
    setIncrementalInspectionRejectedData(data.rejected)
    setIncrementalInspectionLoading(false)
  }


  const getData = async () => {
    setIncrementalInspectionLoading(true)
    if(!id) return navigate("/incremental-damage")
    const res = await makeAuthRequest(`${urlLink}incremental-fleet-inspections`, { tl_id: Cookies.get("tlId"), client_type: Cookies.get("clientType"), client_id: Cookies.get("client"), license: id, agent_id: ""})
    const data1 = await res.json()
    if(res.ok){
        setIncrementalInspectionData(data1)
        setIncrementalInspectionProcessingData(data1.processing)
        setIncrementalInspectionAvailableData(data1.available)
        setIncrementalInspectionRejectedData(data1.rejected)
        setIncrementalInspectionLoading(false)
    }
  }

  
  return (
    <nav className={styles.controlBar}>
      <div className={styles.controlBarContainer}>
        <div className="controlbar-left">
          <button onClick={ () => navigate('/incremental-damage') } className={styles.controlBarBackButton}>
            <img  src={GoBack} alt="Back"  />
            {lang["Back to Menu"][language]}
          </button>
        </div>
        <div className={styles.controlBarForm}>
        <label>{lang["Filter By"][language]}</label>
          <input
            type="date"
            value={incrementalSearchDate}
            onChange={(e) => getFilteredResult("date", e.target.value)}
            placeholder={lang["date"][language]}
            className={styles.controlBarInputDate}
          />
          <input
            type="text"
            value={incrementalSearchInspection}
            onChange={(e) => getFilteredResult("inspection", e.target.value)}
            placeholder="Inspection ID/ Case ID"
            className={styles.controlBarInputId}
          />
          <input
            type="text"
            value={incrementalSearchCarCondtion}
            onChange={(e) => getFilteredResult("car_condition", e.target.value)}
            placeholder="Car Condition"
            className={styles.controlBarInputId}
          />
        </div>
      </div>
    </nav>
  );
};

export default IncrementalVehicleListControlBar;
