import React, { useContext, useEffect, useRef }  from 'react'
import { useNavigate } from 'react-router-dom';
import Car from '../../img/car.png'
import { AppContext } from '../context';
import './Login.scss';
import LoginNavbar from './LoginNavbar';
import DownArrow from '../../img/down.png';
import { makeRequest } from '../context/utils';
import Cookies from 'js-cookie'
import config from '../../config/config.json'

const LoginGenerali = () => {
  const {urlLink} = config
  const { username, password, setUsername, setPassword, selectOpen, setSelectOpen, role, setRole, setLanguage, setModule, loginError, setLoginError, setIsMahindraClient } = useContext(AppContext)
  
  //User types to be sent with authentication data for filter (add new roles/users here in the list below)
  const userType = {"Agent": "agent", "Master": "master", "Team Lead": "team_lead"}

  const navigate = useNavigate()
  const clickRef = useRef()
  const listRef = useRef()

  //Submitting data to backend for authentication
  const handleSubmit = () => {
    window.open(`${urlLink}login_v2`, "_self")
  }

return (
    <div className="main">
        <LoginNavbar />
        <div className="login-content">
          <div className="login-title">
            <p className="title">
            Automate Inspections with AI
            </p>
            <p className="description">Our products detect damages on items using photos & videos, eliminating the need for physical inspections</p>
          </div>
          <div className="login-section">
            <p>Login</p>
          </div>
            <div className="car-grad">
              <button className="btn" onClick={handleSubmit} type="submit">Start</button>
              <img src={Car} alt="car"/>
            </div>
        </div>
         <div className="copy-right">© Copyright 2023 Inspektlabs Inc. All rights reserved.</div> 
    </div>
  )
}

export default LoginGenerali